import React from "react";
import "./about.scss"
import logo from "../../Media/cropped-cropped-LOGO-CITRALAND-SURABAYA-1024x541-1.webp"

const abouttalasa = () => {
    return (
        <div className="container-abouttalasa">
                <div className="logo-abouttalasa">
                    <img className="gambar-logotalasa" src={logo} alt="logo-citraland" />
                </div>
            <div className="container-deskripsitalasa">
                <div className="deskripsi-abouttalasa">
                Citraland Tallasa City adalah proyek pembangunan kota di pusat Makassar, Sulawesi Selatan, Indonesia. Area pengembangan seluas 120 hektar dibangun oleh Ciputra Group bekerja sama dengan FKS Land. Dengan konsep "Grand, Green, and Beautiful", Citraland Tallasa City menggabungkan pusat bisnis modern, mall, apartment, sekolah, universitas, rumah sakit, klub, dan waterpark. Proyek ini menawarkan berbagai fasilitas kota yang lengkap dan strategis, serta berbagai jenis rumah yang sesuai dengan kebutuhan dan anggaran penghuni.
                </div>
            </div>
        </div>
    );
};

export default abouttalasa;