import Vittorio1 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (1).png'
import Vittorio2 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (2).png'
import Vittorio3 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (3).png'
import Vittorio4 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (4).png'
import Vittorio5 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (5).png'
import Vittorio6 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (6).png'
import Vittorio7 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (7).png'
import Vittorio8 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (8).png'
import Vittorio9 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (9).png'
import Vittorio10 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (10).png'
import Vittorio11 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (11).png'
import Vittorio12 from '../../../Media/Ciputra Talasa/Vittoria/Vittoria (12).png'

const Vittorioimages =[
Vittorio1,Vittorio2,Vittorio3,Vittorio4,Vittorio5,Vittorio6,Vittorio7,Vittorio8,Vittorio9,Vittorio10,Vittorio11,Vittorio12
]

export default Vittorioimages;