import React from 'react'
import './header.scss'
import Logo from './../../Media/cropped-cropped-LOGO-CITRALAND-SURABAYA-1024x541-1.webp'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'


const headertalasa = () => {

    const waheader = () => {
        const whatsappLink =
          "https://api.whatsapp.com/send/?phone=6281527816144&text=Halo%20Marketing%20Citraland,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Citraland%20Tallasa%20https://citraland-tallasa.com//&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    }
  return (
    <div className='container-headertalasa'>
        <div className='logotalasa'>
            <img className='gambartalasa' src={Logo} alt="logo-talasa" />
        </div>
        <div className='container-buttonwa'>
            <button className= 'button' onClick={waheader}>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </button>
        </div>
    </div>
  )
}

export default headertalasa;