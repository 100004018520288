import React from 'react'
import "./lokasi.scss"
import lokasiimages from "../../Media/lokasi.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";


const lokasi = () => {
    const handleConsultationClick = () => {
        const whatsappLink =
          "https://api.whatsapp.com/send/?phone=6281527816144&text=Halo%20Marketing%20Citraland,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20Citraland%20https://citraland-tallasa.com//&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    }
  return (
    <div className="container-lokasi">
        <div className="container-judul">
            <div className="judul-lokasi">
                <h1>Lokasi </h1>
                <h2>Citraland Tallasa</h2>
                <h3>Makassar</h3>
                <div className='deskripsi'> 
                <h4>Lokasi yang ideal</h4>
                <h5>untuk tinggal dan berbisnis</h5>
                <h6>dengan banyak infrastruktur</h6>
                </div>
            </div>
            <div className="button-penawaran"><button className="buttonpenawaran" onClick={handleConsultationClick}>
                     Learn More
                </button>
            </div>
        </div>
        <div id='lokasi' className="lokasi">
             <img className="lokasi-tallasa" src={lokasiimages} alt="hardsell-summarecon-serpong" />
        </div>
    </div>
  )
}

export default lokasi;