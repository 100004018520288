import Winetta1 from '../../../Media/Ciputra Talasa/Winetta/Winetta (1).png'
import Winetta2 from '../../../Media/Ciputra Talasa/Winetta/Winetta (2).png'
import Winetta3 from '../../../Media/Ciputra Talasa/Winetta/Winetta (3).png'
import Winetta4 from '../../../Media/Ciputra Talasa/Winetta/Winetta (4).png'
import Winetta5 from '../../../Media/Ciputra Talasa/Winetta/Winetta (5).png'
import Winetta6 from '../../../Media/Ciputra Talasa/Winetta/Winetta (6).png'
import Winetta7 from '../../../Media/Ciputra Talasa/Winetta/Winetta (7).png'
import Winetta8 from '../../../Media/Ciputra Talasa/Winetta/Winetta (8).png'
import Winetta9 from '../../../Media/Ciputra Talasa/Winetta/Winetta (9).png'
import Winetta10 from '../../../Media/Ciputra Talasa/Winetta/Winetta (10).png'
import Winetta11 from '../../../Media/Ciputra Talasa/Winetta/Winetta (11).png'
import Winetta12 from '../../../Media/Ciputra Talasa/Winetta/Winetta (12).png'

const Winettaimages =[
Winetta1,Winetta2,Winetta3,Winetta4,Winetta5,Winetta6,Winetta7,Winetta8,Winetta9,Winetta10,Winetta11,Winetta12
]

export default Winettaimages;