import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Sicilyimages from "./Sicily.js";
import Vittorioimages from "./Vittorio.js";
import Melroseimages from "./Melrose.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faShower } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };



  const Sicilywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281527816144&text=Halo%20Marketing%20Citraland,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Sicily%20https://citraland-tallasa.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Melrosewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281527816144&text=Halo%20Marketing%20Citraland,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Melrose%20https://citraland-tallasa.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }
  const Vittorioawa = () => {  
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281527816144&text=Halo%20Marketing%20Citraland,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Vittorio%20https://citraland-tallasa.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }


  return (
    <div className="container">
      <div className="containercard1">
      <div className="card">
          <Slider {...settings}>
            {Sicilyimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Sicily</div>
              <span>Free PPN</span>
            </div>
            <div className="cicilan">
              <div className="angka">11</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#0f1c3f' icon={faArrowsUpDownLeftRight} />
            <span> : 105m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faHouse} />
            <span> : 107m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faBed} />
            <span>: 3</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faShower} />
            <span>: 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Sicilywa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Melroseimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Melrose</div>
              <span>Free PPN</span>
            </div>
            <div className="cicilan">
              <div className="angka">14</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#0f1c3f' icon={faArrowsUpDownLeftRight} />
            <span> : 120m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faHouse} />
            <span> : 149m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faBed} />
            <span>: 3+1</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faShower} />
            <span>: 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Melrosewa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Vittorioimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Vittorio</div>
              <span>Free PPN</span>
            </div>
            <div className="cicilan">
              <div className="angka">15</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#0f1c3f' icon={faArrowsUpDownLeftRight} />
            <span> : 120m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faHouse} />
            <span> : 120m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faBed} />
            <span>: 3+1</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faShower} />
            <span>: 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Vittorioawa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk2;
