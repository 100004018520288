import Tuscany1 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (1).png'
import Tuscany2 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (2).png'
import Tuscany3 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (3).png'
import Tuscany4 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (4).png'
import Tuscany5 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (5).png'
import Tuscany6 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (6).png'
import Tuscany7 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (7).png'
import Tuscany8 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (8).png'
import Tuscany9 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (9).png'
import Tuscany10 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (10).png'
import Tuscany11 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (11).png'
import Tuscany12 from '../../../Media/Ciputra Talasa/Tuscany/Tuscany (12).png'

const Tuscanyimages =[
Tuscany1,Tuscany2,Tuscany3,Tuscany4,Tuscany5,Tuscany6,Tuscany7,Tuscany8,Tuscany9,Tuscany10,Tuscany11,Tuscany12
]

export default Tuscanyimages;