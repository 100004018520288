import React from 'react'
import './fasilitas.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import fasilitasimages from './fasilitas.js';


const fasilitas = () => {
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
  return (
    <div id='fasilitas' className='container-fasilitas'>
        <hr />
        <div className='judul-fasilitas'>
            <h1>CLUSTER FACILITY</h1>
        </div>
        <div className='container-card'>
        <div className='kartu'>
        <Slider {...settings}>
            {fasilitasimages.map((image, index) => (
              <div>
                <img
                  className="kartugambar1"
                  key={index}
                  src={image}
                  alt="athena"
                />
              </div>
            ))}
          </Slider>
        </div>
        </div>
    </div>
  )
}

export default fasilitas;