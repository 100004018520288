import React from "react";
import "./footer.scss";
import logo from "../../Media/cropped-cropped-LOGO-CITRALAND-SURABAYA-1024x541-1.webp";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img
            className="gambar-logo"
            src={logo}
            alt="logo-citraland-surabaya"
          />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Citraland Tallasa</h2>
            <h3>Jl. Jalur Lingkaran Barat, Kapasa, Kec. Tamalanrea, Kota Makassar, Sulawesi Selatan 90241</h3>
            <h4>Contact us : +6281527816144 (Mitra)</h4>
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">© 2024 All Rights Reserved.</div>
    </div>
  );
};

export default footer;