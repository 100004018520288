import Fasilitas1 from '../../Media/Fasilitas/1.png'
import Fasilitas2 from '../../Media/Fasilitas/2.png'
import Fasilitas3 from '../../Media/Fasilitas/3.png'
import Fasilitas4 from '../../Media/Fasilitas/4.png'
import Fasilitas5 from '../../Media/Fasilitas/5.png'
import Fasilitas6 from '../../Media/Fasilitas/6.png'
import Fasilitas7 from '../../Media/Fasilitas/7.png'
import Fasilitas8 from '../../Media/Fasilitas/8.png'
import Fasilitas9 from '../../Media/Fasilitas/9.png'



const fasilitasimages =[
    Fasilitas1,Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5,Fasilitas6,Fasilitas7,Fasilitas8,Fasilitas9
]

export default fasilitasimages;