import React from "react";
import "./promo.scss";
import penawaran from "../../Media/design hardsel 34 (13).png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281527816144&text=Halo%20Marketing%20Citraland,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20Citraland%20Tallasa%20https://citraland-tallasa.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="a">
      <div id="promo" className="judul">
        <h1>OUR PROMO</h1>
      </div>
      <div id="promo" className=" containerPenawaran">
        <div className="containercontent">
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Cicilan Start 4 Juta</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free PPN
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free Biaya-biaya</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Smarthome System
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free Instalasi Listrik 2.200 Watt</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free Instalasi Air Bersih</span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Learn More
          </button>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={penawaran} />
        </div>
      </div>
    </div>
  );
};

export default Penawaran;
